import { useState, useEffect } from 'react';
import backgroundvideo from "../../files/ryanair_plane.mp4"
import "./videoStyle.css";

function VideoBackground() {
    const [windowSize, setWindowSize] = useState(getWindowSize());
    useEffect(() => {
        setWindowSize(getWindowSize());

    }, []);

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }


    function IDVideo() {
        if (windowSize.innerWidth >= 800) {
            return "video_background";
        } else {
            return "video_background_mobile";
        }
    }

    return (
        <video id={IDVideo()} loop muted autoPlay={"autoplay"} playsInline>
            <source
                src={backgroundvideo}
                type="video/mp4"
            />
            Your browser does not support the video tag.
        </video>
    );
}

export default VideoBackground;
