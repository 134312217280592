import {
    React,
    useEffect,
    useState,
    useContext,
    useReducer
} from 'react';
import $ from "jquery";
import "../form/loginFormStyle.css"
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import format from 'date-fns/format';
//const { EncryptStorage } = require('encrypt-storage');
import jwt from 'jwt-decode';
import Link from '@mui/material/Link';

function ControlUser(props) {

    const { open, onClose } = props;



    const ControlInfo = () => {

        if (props.data != undefined && props.data.length != 0) {
            if (props.data[0].TipoUser == "11" || props.data[0].TipoUser == "14" || props.data[0].TipoUser == "15") {
                switch (props.data[0].Confirmado) {
                    case "RE": return <p>Uninitialized user, please contact your manager to obtain the initial password</p>
                    case "Si": return <p>You have forgotten your password, contact your manager to solve it</p>
                    case "No": return <p>User blocked in the system, contact your manager to modify their status</p>
                }
            } else {
                return (
                    <div style={{padding:"50px"}}>
                        <Link href={"../EnviaEmailConfirmacionOlvidado.php?user=" + props.data[0].username} underline="hover">
                            {'Change password'}
                        </Link>
                    </div>

                )
            }
        } else {
            return <p>The user doesn`t exist. If you need a user, contact your manager</p>
        }
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={() => onClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className="header_dialog">
                    {"User"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className="content_dialog">
                        {ControlInfo()}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default ControlUser; 